<template>
  <van-nav-bar
  title="fwry"
  left-arrow
  @click-left="onGoBack"
  />
 <!--<van-cell v-for="item in userlist.data" :key="item"   @click="getinfo(item)">-->
  <div v-if="is_data">
  <van-cell v-for="item in userlist.data" :key="item"   @click="getinfo(item)">
    <template #title>
  
    <van-image
     width="30"
     height="30"
     src="people.png"
    />
    <span style="margin-left:20px" @click="getinfo(item)">{{structure2(item.userName)}}({{structure(''+item.userPhoneNum)}})</span>
    </template>
    <template #value>
      {{item.userJoinDatetime}}
    </template>
  </van-cell>
  </div>
  <van-pagination 
    v-model="currentPage"
    :total-items="pagination.totalitems"
    :items-per-page="pagination.itemsperpage"
    @change="onChangePage"
  />
</template>

<script>
import { ref ,getCurrentInstance,onMounted, reactive,} from 'vue';
import { useRouter } from 'vue-router';
import {loginout} from '@/service/user'
import { useUserStore } from '@/store/user.ts'

const base_url=process.env.VUE_APP_API_PORT_BASE_URL
export default {
  setup() {
    
    const is_data=ref(false)
    const userStore = useUserStore()
    let userlist= reactive({
      data: [{}]});
    
    let pagination=reactive({
      totalitems:null,
      currentpage:1,
      itemsperpage:15,
    })

    const currentPage = ref();
    let { proxy } = getCurrentInstance();

    onMounted( () => {
      getuserinfo()
    });
    
    const onChangePage=()=>{
       pagination.currentpage=currentPage.value
      //pager.current = pagination.current;
        getuserinfo()
    }

    const getuserinfo=()=>{
      let params = {data: {}}
      params={
        userName:userStore.userInfo.name,
        userPhoneNum:userStore.userInfo.phone,
        current:pagination.currentpage,
        size:pagination.itemsperpage
      }
      let tokens={'Authorization':userStore.userInfo.token}
      proxy.$axios.post(base_url+'/user/getUserListByUsernameAndPhonenum',params,{headers:tokens})
      .then(res=>{
        if(res.data.length==0){
          loginout()
          this.$toast('登录已失效，请重新登录！')
        }
        userlist.data.length=0;
        for (var i=0;i<res.data.data.data.length;i++) {
          userlist.data.push(res.data.data.data[i])
        }
        pagination.totalitems = res.data.data.total;
        pagination.itemsperpage=15;
        is_data.value=true;
      }).catch(err=>{
        console.log('err'+err)
      })
    };
    
    const structure2=(array)=>{
      var str='';
      for(var i=0;i<array.length;i++){
        if(i==1){
          str=str+'*';
        }else{
          str=str+array[i];
        }
      }
      return str;
    };

    const structure=(array)=>{
      let arrBox=[...array];
      var str='';
      arrBox.map((res,index)=>{
        if(index>3 && index<8){
          str+='*';
        }else{
          str+=res;
        }
      })
      return str;
    };

    const router=useRouter();
    const getinfo=(item)=>{
      let aa=JSON.stringify(item)
      router.push({name:'userinfo',params:{data:aa}})
      //alert(item.userName)
    }

    return {
      is_data,
      currentPage,
      userlist,
      pagination,
      getinfo,
      getuserinfo,
      onChangePage,
      structure,
      structure2
    };
  },
};
  
</script>
<style lang="less">





</style>
